<template>
  <QuestionnaireBase :title="title" :form="form" :finishFunction="null">
    <template v-slot:default>
      <component :is="show" :key="1" :ref="show" v-model="form"/>
      <component
          v-if="load in $options.components"
          v-show="false"
          :is="load"
          key="load"
          :ref="load"
          v-model="form"
      />
    </template>
  </QuestionnaireBase>
</template>

<script>
import {loadHelpers} from "../loadHelpers";

import QuestionnaireBase from "../../../components/common/questionnaires/questionnaire";
import NameStatusFull from "../../../components/common/questionnaires/fieldsets/full/NameStatusFull";
import RelationshipFull from "@/components/common/questionnaires/fieldsets/full/RelationshipFull";
import ContactFull from "../../../components/common/questionnaires/fieldsets/full/ContactFull";
import AddressFull from "../../../components/common/questionnaires/fieldsets/full/AddressFull";
import ChildrenDependantsFull from "../../../components/common/questionnaires/fieldsets/full/ChildrenDependantsFull";
import AssetsFull from "../../../components/common/questionnaires/fieldsets/full/AssetsFull";
import PoliciesFull from "../../../components/common/questionnaires/fieldsets/full/PoliciesFull";
import LiabilitiesFull from "../../../components/common/questionnaires/fieldsets/full/LiabilitiesFull";
import CommitmentsFull from "../../../components/common/questionnaires/fieldsets/full/CommitmentsFull";
// import IncomeFull from "../../../components/common/questionnaires/fieldsets/full/IncomeFull";

export default {
  name: "FactFindFull",
  mixins: [loadHelpers],
  components: {
    ContactFull,
    NameStatusFull,
    RelationshipFull,
    QuestionnaireBase,
    AddressFull,
    ChildrenDependantsFull,
    AssetsFull,
    PoliciesFull,
    LiabilitiesFull,
    CommitmentsFull,
    // IncomeFull
  },
  data() {
    return {
      name: 'FactFindFull',
      title: "Full Fact Find",
      url: 'fact_find_full',
      order: [ // imported components required here to load
        'NameStatusFull',
        'RelationshipFull',
        'ContactFull',
        'AddressFull',
        'ChildrenDependantsFull',
        'AssetsFull',
        'PoliciesFull',
        'LiabilitiesFull',
        'CommitmentsFull',
        // 'IncomeFull',
      ],
      expiryAccessCodeOnFinish: true

    };
  }
};
</script>

<template>
  <div class="fieldset">
    <h3 class="fieldset-heading">Policies</h3>

    <PoliciesMain ref="policiesMain" v-model="form.policies_main" @refresh="refreshPolicies"/>

    <PoliciesOverYN v-model="form.policies_other_type" :policies="form.policies_other"/>

    <div v-if="form.policies_other_type" class="question-box with-arrow">
      <PoliciesOther ref="policiesOther" v-model="form.policies_other" @refresh="refreshPolicies"/>
    </div>

  </div>
</template>

<script>
import {fieldsetHelpers} from "../fieldsetHelpers";
import PoliciesOther from "../../question/questions/clientProfile/policies/PoliciesOther";
import PoliciesOverYN from "../../question/questions/clientProfile/policies/PoliciesOverYN";
import PoliciesMain from "../../question/questions/clientProfile/policies/PoliciesMain";

export default {
  name: 'PoliciesFull',
  mixins: [fieldsetHelpers],
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  components: {
    PoliciesMain,
    PoliciesOverYN,
    PoliciesOther

  },
  data() {
    return {
      label: 'Policies' // used for steps progress
    }
  },
  computed: {
    form: {
      set(value) {
        this.$emit('input', value)
      },
      get() {
        return this.value
      }
    }
  },
  methods: {
    refreshPolicies() {
      if ('policiesMain' in this.$refs) this.$refs.policiesMain.get()
      if ('policiesOther' in this.$refs) this.$refs.policiesOther.get()
    }
  }
};
</script>

<template>
  <div class="fieldset">
    <h3 class="fieldset-heading">Contact Information</h3>
    <MobileNumber
        v-model="form.profileMobileNumber" />
    <HomeNumber
        v-model="form.profileHomeNumber" />
    <WorkNumber
        v-model="form.profileWorkNumber" />
    <Email
        v-model="form.profileEmail" />
    <ContactPreferences
        v-model="form.profileContactNotes" />
    <MarketingConsent
        v-model="form.profileMarketingConsent"
        :consent-date="form.profileMarketingConsentDate" />
  </div>
</template>

<script>
import { fieldsetHelpers } from "../fieldsetHelpers";

import MobileNumber from "../../question/questions/clientProfile/contact/MobileNumber";
import WorkNumber from "../../question/questions/clientProfile/contact/WorkNumber";
import HomeNumber from "../../question/questions/clientProfile/contact/HomeNumber";
import Email from "../../question/questions/clientProfile/contact/Email";
import ContactPreferences from "../../question/questions/clientProfile/contact/Preferences";
import MarketingConsent from "../../question/questions/clientProfile/contact/Marketing";

export default {
  name: 'ContactFull',
  mixins: [fieldsetHelpers],
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  components: {
    MarketingConsent,
    ContactPreferences,
    Email,
    HomeNumber,
    WorkNumber,
    MobileNumber
  },
  data () {
    return {
      label: 'Contact Details' // used for steps progress
    }
  },
  computed: {
    form: {
      set(value) {
        this.$emit('input', value)
      },
      get () {
        return this.value
      }
    }
  }
};
</script>
